<template>
  <div class="contents-area not-found-contents">
    <div class="full-content">
      <div class="container">
        <div class="h1-breadcrumbs">
          <h1>{{ translations("OrderSuccessfullyCreated") }}</h1>
          <Breadcrumbs />
        </div>
        <div class="order-info order-success-page">
          <div class="text-left">
            <p v-html="listSettings.order_successSettings.seo_text"></p>
            <table width="100%">
              <tbody>
                <tr>
                  <th>{{ translations("OrderNumber") }}</th>
                  <td>{{ listOrder.order_id }}</td>
                </tr>
                <tr>
                  <th>{{ translations("OrderFIO") }}</th>
                  <td>{{ listOrder.first_name }}</td>
                </tr>
                <tr>
                  <th>{{ translations("OrderPaymentMethod") }}</th>
                  <td>
                    {{ paymentMethodName }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th>{{ translations("OrderProducts") }}:</th>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr
                  v-for="(product, index) in listOrder.products"
                  :key="product.id"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      product.company_name +
                      (product.company_inn ? " - " + product.company_inn : "") +
                      " (" +
                      product.company_country +
                      ")"
                    }}<br />
                    {{ product.name }}<br />
                  </td>
                  <td>€ {{ Math.round(product.price) }}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <a
              v-if="listOrder.payment_method === 'raschSch'"
              target="_blank"
              :href="'//' + serverLink + listOrder.schet_doc"
              class="btn btn-blue"
              >{{ translations("PrintSchet") }}</a
            ><br /><br />
            <router-link to="/" class="link">{{
              translations("BackToHome")
            }}</router-link>
          </div>
          <div class="logo-right">
            <img
              :src="'//' + serverLink + '/upload/image/egrul/new_order.svg'"
              alt="New Order"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "OrderSuccess",
  components: {},
  data: () => ({
    serverLink: VUE_APP_BACK_URL,
    paymentMethodName: "",
  }),
  metaInfo() {
    return {
      title:
        this.listSettings.order_successSettings.meta_title +
        " | " +
        this.listLang.site_name,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.listSettings.order_successSettings.meta_desc,
        },
        {
          name: "keywords",
          content: this.listSettings.order_successSettings.meta_keywords,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "translations",
      "listSettings",
      "listLang",
      "listOrder",
      "ListLoggedUser",
    ]),
  },
  methods: {
    ...mapActions(["incrementBoughts"]),
  },
  mounted() {
    const paymentNames = {
      raschSch: this.translations("ToBankAccount"),
      bankCard: this.translations("BankCard"),
      webMoney: this.translations("ToWebmoney"),
      youMoney: this.translations("ToYooMoney"),
      qiwi: this.translations("ToQiwi"),
      payPal: this.translations("ToPayPal"),
    };
    this.paymentMethodName = paymentNames[this.listOrder.payment_method];

    if (this.listOrder.payment_method !== "raschSch") {
      // достижение цели покупки
      // делается только если оплата не по счету, то есть произошла реальная оплата
      // если по счету - то данные об этой цели прокинутся в метрику из админки,
      // когда проставится оплата для этого заказа

      if (this.ListLoggedUser.boughts === 0) {
        this.$metrika.reachGoal("productBuyNew");
      } else {
        this.$metrika.reachGoal("productBuyOld");
      }

      // после реальной оплаты нужно увеличить покупки. 
      // Но после того как достигнем нужных целей, 
      // чтобы последняя покупка не влияла на их достижение
      this.incrementBoughts(); 
    }
  },
};
</script>